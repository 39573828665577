const env = process.env.NODE_ENV || "development";

const apiEnvironment = {
  development: {
    api: "https://vsip-api.cgis.asia/",
    media_url: "https://vsip-api.cgis.asia/",
    domainAdminSide: "http://localhost:3000",
    domainUserSide: "http://localhost:3001",
    domainName: "",
    workSpace: "vsip",
    wmsBaseLink: "https://geo.cgis.asia/geoserver/vsip/wms/",
  },
  production: {
    api: "https://vsip-api.cgis.asia/",
    media_url: "https://vsip-api.cgis.asia/",
    domainAdminSide: "https://admin.vsip.cgis.asia",
    domainUserSide: "https://vsip.cgis.asia",
    domainName: "vsip.cgis.asia",
    workSpace: "vsip",
    wmsBaseLink: "https://geo.cgis.asia/geoserver/vsip/wms/",
  },
};

module.exports = apiEnvironment[env];
